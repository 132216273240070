import React from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { IconName } from '@components/Icon';
import ShareModal from '@components/ShareModal';
import { useUser } from '@/hooks/useUser';
import { PropertyType } from '@/utilities/constants';

interface ShareListingModalProps {
  show: boolean;
  listingId: string;
  propertyType: string | PropertyType;
  onClose: () => void;
}
const checkBox = { label: 'Attach a flyer for this listing' };
export const ShareListingModal: React.FC<ShareListingModalProps> = ({
  show,
  onClose,
  listingId,
  propertyType,
}: ShareListingModalProps) => {
  const { shareFlyerViaEmail } = useApiClient();
  const { addToast } = useToast();
  const { email, firstName, lastName } = useUser();
  const { mutate: shareFlyerViaEmailMutation } = shareFlyerViaEmail(propertyType);

  const handleCopyLink = () => {
    const link = document.URL.replace(location.pathname, '/listing/' + listingId);
    navigator.clipboard.writeText(link);

    addToast({
      id: 'share-listing-' + listingId,
      description: 'URL Copied to Clipboard',
      title: 'Success',
      type: 'success',
    });
  };

  const handleSendEmail = (recipient: string[], attachFlyer: boolean) => {
    shareFlyerViaEmailMutation({
      recipient,
      listingId,
      attachFlyer,
      senderFirstName: firstName!,
      senderLastName: lastName!,
      toEmail: email!,
    });

    const descriptionText =
      recipient.length === 1
        ? `Email sent to ${recipient.length} address`
        : `Email sent to ${recipient.length} addresses`;

    addToast({
      id: 'share-listing-' + listingId,
      description: descriptionText,
      title: 'Success',
      type: 'success',
    });
  };

  return (
    <ShareModal
      show={show}
      onClose={onClose}
      header="Share Listing"
      mainActionIcon={IconName.LINK}
      mainActionLabel="Copy Link"
      onMainAction={handleCopyLink}
      onSendEmail={handleSendEmail}
      attachReportCheckBox={checkBox}
    />
  );
};
