import { LngLatBoundsLike } from 'mapbox-gl';

export const API_LISTINGS_BASE_URL = process.env.REACT_APP_API_LISTINGS_BASE_URL!;

export const APIM_SUBSCRIPTION_KEY = process.env.REACT_APP_APIM_SUBSCRIPTION_KEY!;
export const APIM_SCOPE_URI = process.env.REACT_APP_APIM_SCOPE_URI!;

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN!;

export const AAD_CLIENT_ID = process.env.REACT_APP_AAD_CLIENT_ID!;
export const AAD_TENANT_ID = process.env.REACT_APP_AAD_TENANT_ID!;

export const MAP_DEFAULT_LONGITUDE = -97.2884;
export const MAP_DEFAULT_LATITUDE = 39.0951;
export const MAP_DEFAULT_ZOOM = 3.6;

export const MAP_CLUSTERING_RADIUS = 25;
export const MAP_CLUSTERING_MAX_ZOOM = 16;

export const MAP_3D_FLY_ZOOM = 16;
export const MAP_3D_ZOOM_THRESHOLD = 14;

export const MAP_FLY_ZOOM = 8;

export const MAP_DEFAULT_BOUNDS = [
  -133.95760152618777, 21.8590408886254, -61.44428480362923, 51.55966831391703,
] as LngLatBoundsLike;

export const DISTANCE_FILTER_PADDING = 40;

export const MAP_STYLES = [
  {
    id: 'streets',
    displayName: 'Streets',
    url: String(process.env.REACT_APP_MAPBOX_ACCESS_STREET_STYLE_MAP_URL!),
  },
  {
    id: 'satellite',
    displayName: 'Satellite',
    url: String(process.env.REACT_APP_MAPBOX_ACCESS_SATELLITE_STYLE_MAP_URL!),
  },
];

// NOTE - This causes the application to perform a single fetch of every
// listing initially, then perform filtering on the frontend. This is an
// alternative to refetching the filtered listings every time the criteria
// change.

export const FEATURE_FLAG_USE_FRONTEND_FILTERING =
  String(process.env.REACT_APP_FEATURE_FLAG_USE_FRONTEND_FILTERING!) === 'true';

// NOTE - This enables the Create Availability Report from Results Panel
// functionality. See MAR-1276 for more details.

export const FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT =
  String(process.env.REACT_APP_FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT!) === 'true';

// NOTE - This enables the Email Alerts on Saved Searches functionality.
// This hides/shows the toggle button on the Saved Searches

export const FEATURE_FLAG_ENABLE_SAVEDSEARCH_EMAIL_ALERTS =
  String(process.env.REACT_APP_FEATURE_FLAG_ENABLE_SAVEDSEARCH_EMAIL_ALERTS!) === 'true';

// NOTE - This enables the AppSwitcher icon on the Header.

export const FEATURE_FLAG_ENABLE_APP_SWITCHER =
  String(process.env.REACT_APP_FEATURE_FLAG_ENABLE_APP_SWITCHER!) === 'true';

// NOTE - This enables the Listing type switcher on the Header.

export const FEATURE_FLAG_ENABLE_INDUSTRIAL_LISTINGS =
  String(process.env.REACT_APP_FEATURE_FLAG_ENABLE_INDUSTRIAL_LISTINGS!) === 'true';

// NOTE - Pagination: Records size per page.

export const FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE =
  Number(process.env.REACT_APP_FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE) ?? 50;

// NOTE - Limit for the number of listings that can be in the availability report.
export const REPORT_MAX_LISTINGS = Number(process.env.REACT_APP_REPORT_MAX_LISTINGS);

// NOTE - Limit for the number of listings that can be in the vancancy report.
export const VACANCY_REPORT_MAX_LISTINGS = Number(
  process.env.REACT_APP_VACANCY_REPORT_MAX_LISTINGS,
);

// NOTE - This enables the Listing type switcher on the Header.

export const FEATURE_FLAG_ENABLE_DISTANCE_FILTER =
  String(process.env.REACT_APP_FEATURE_FLAG_ENABLE_DISTANCE_FILTER!) === 'true';
