import cn from 'classnames';
import { format } from 'date-fns';
import React, { useMemo } from 'react';

import { FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT } from '@/config';
import { useCart } from '@/hooks/useReportCart';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import TransparentCheckbox from '@components/Inputs/TransparentCheckbox';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography/Typography';
import { SearchResultCardProps } from './SearchResultCard.types';

import { useToast } from '@/hooks/useToast';
import { ActiveFilterQueryParam as SearchParameterNames } from '@/types';
import { PropertyType, isIndustrialOrPark, isiOS } from '@/utilities/constants';
import { useMediaQuery } from 'react-responsive';
import './SearchResultCard.css';
import { Tooltip } from 'react-tooltip';

const SearchResultCard: React.FC<SearchResultCardProps> = ({
  acreage,
  address,
  clearHeight,
  dateAvailable,
  dockDoors,
  driveInDoors,
  id,
  imageUrl,
  buildingStatus,
  onFavoriteSelect,
  onResultSelect,
  propertySize,
  propertyType,
  handleRemoveFavorite,
  unitName,
  listingViewCount,
}) => {
  const { selectedListing, listings, selectedOption } = useMapData();
  const { secondaryHover, setSecondaryHover, setResultCardHover } = useMapActions();
  const { addFavorite, favorites, isFavorite, pendingFavorites, updatePendingFavorites } =
    useUserFavorites();
  const { addToast } = useToast();

  const searchParameter = new URLSearchParams(location.search).get(
    SearchParameterNames.INITIAL_VIEW_STATE,
  )!;
  const searchParameterParts = searchParameter && searchParameter.split('|');

  const isSelectedListing =
    selectedListing?.id === id ||
    (searchParameterParts &&
      searchParameterParts[3] === 'listingNames' &&
      searchParameterParts[2] === id);

  const isPropertyAddressSelected =
    selectedOption?.category === 'propertyAddresses' ||
    (searchParameterParts && searchParameterParts[3] === 'propertyAddresses');
  const searchedPropertyAddress = new URLSearchParams(location.search).get(
    SearchParameterNames.SEARCHED_LOCATION,
  )!;
  const isSearchedPropertyAddress =
    selectedOption?.displayName === address || searchedPropertyAddress === address;
  const isSecondaryHover = secondaryHover?.id === id;

  const { addToCart, removeFromCart, isAdded } = useCart();
  const isSaved = useMemo(() => isFavorite(id), [favorites]);

  const ios = isiOS();
  const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const isSmallAndMediumScreen = useMediaQuery({ query: '(max-width: 1023px)' });

  const showFavortiteToast = (
    listingId: string,
    description: string,
    title: string,
    type: 'success' | 'error' | 'warning' | 'info',
  ) => {
    addToast({
      id: 'generate-flyer-' + listingId,
      description: description,
      title: title,
      type: type,
    });
  };

  const handleClickOnStar = () => {
    if (isSaved) {
      handleRemoveFavorite(id);
    } else {
      updatePendingFavorites([...pendingFavorites, id]);
      addFavorite(id).catch((err) => {
        showFavortiteToast(id, 'Try again', 'Listing Not Favorited', 'error');
        updatePendingFavorites(pendingFavorites.filter((f) => f !== id));
      });
    }
  };
  const getSizeUnit = (propertyType: string, unitOfMeasure: string) => {
    if (isIndustrialOrPark(propertyType)) {
      return unitOfMeasure ? unitOfMeasure.replace('Avail Sq Ft', 'Unit Sq Ft') : 'Unit Sq Ft';
    } else {
      return unitOfMeasure ?? 'Avail Sq Ft';
    }
  };

  const availableTime = useMemo(() => {
    // BTS
    if (!dateAvailable || dateAvailable === 'CONTACT_US') {
      return <div className="mr-[1rem]">Contact us for availability</div>;
    } else if (
      dateAvailable === 'Now' ||
      dateAvailable === 'Vacant' ||
      new Date(dateAvailable) <= new Date()
    ) {
      return <div className="mr-[1rem]">Available Now</div>;
    } else if (
      buildingStatus === 'BUILD_TO_SUIT' ||
      buildingStatus === 'NEW_SPECULATIVE_DEVELOPMENT' ||
      buildingStatus === 'UNDER_CONSTRUCTION'
    ) {
      const value = new Date(dateAvailable);
      const quarter = Math.ceil((value.getMonth() + 1) / 3);
      return (
        <div className="mr-[1rem]">
          Available Q{quarter} ‘{format(value, 'yy')}
        </div>
      );
    } else if (
      // Industrial or Parks
      isIndustrialOrPark(propertyType)
    ) {
      const timeSplit = dateAvailable.split('T');
      const dateString = timeSplit[0];
      const dateSplit = dateString.split('-');
      const expectedFormat = dateSplit[1] + '/' + dateSplit[2] + '/' + dateSplit[0];
      return <div className="mr-[1rem]">Potentially Available {expectedFormat}</div>;
    } else {
      return '';
    }
  }, []);

  const BuildingStatusText = () => {
    switch (buildingStatus) {
      case 'BUILD_TO_SUIT':
        return 'build to suit opportunity';
      case 'NEW_SPECULATIVE_DEVELOPMENT':
        return 'new class a development';
      case 'UNDER_CONSTRUCTION':
        return 'under construction';
      default:
        return;
    }
  };

  const showListingViewCount = () => {
    // Disable Views count on Card. Ref: https://linklogistics.atlassian.net/browse/MAR-2767
    return false;
  };

  const updateCart = (id: string, name: string) => {
    isAdded({ listingId: id, listingName: name })
      ? removeFromCart({ listingId: id, listingName: name })
      : addToCart({ listingId: id, listingName: name });
  };

  return (
    <div key={id}>
      <div className="card-options absolute flex justify-between">
        {FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT && (
          <span className="ml-4 z-[100] p-3">
            {isSmallAndMediumScreen ? (
              <input
                type="checkbox"
                id="myCheckbox"
                checked={isAdded({ listingId: id, listingName: unitName })}
                onChange={() => updateCart(id, unitName)}
                className="tablet-checkbox w-[22px] h-[22px] bg-white-500/[.80] border-white border-1"
              />
            ) : (
              <TransparentCheckbox
                isChecked={isAdded({ listingId: id, listingName: unitName })}
                onCheck={() => {
                  addToCart({ listingId: id, listingName: unitName });
                }}
                onUnCheck={() => {
                  removeFromCart({ listingId: id, listingName: unitName });
                }}
              />
            )}
          </span>
        )}
        {!isBigScreen && (
          <span
            className="sm:z-[100] mt-3 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleClickOnStar();
            }}>
            {isSaved || pendingFavorites.includes(id) ? (
              <Icon name={IconName.STAR_FILLED} />
            ) : (
              <Icon classNames={cn(['fav-icon'])} name={IconName.STAR} />
            )}
          </span>
        )}
      </div>

      <div
        className={cn([
          'result-card relative',
          isSelectedListing && 'selected',
          !isSelectedListing &&
            isPropertyAddressSelected &&
            isSearchedPropertyAddress &&
            'selected',
          isSecondaryHover && 'secondary-hover',
          'flex lg:flex-row flex-col sm:min-h-[280px] md:min-h-full',
        ])}
        onMouseEnter={() => {
          const listing = listings.find((x) => x.id === id) ?? null;
          setResultCardHover(listing);
          if (selectedListing && listing?.id !== selectedListing.id) {
            setSecondaryHover(listing);
          }
          !isBigScreen && listing?.id && ios && onResultSelect(listing.id.toString());
        }}
        onMouseLeave={() => {
          setResultCardHover(null);
          setSecondaryHover(null);
        }}
        onClick={(e) => {
          onResultSelect(id);
        }}>
        <div className="result-card-image relative h-36 lg:h-40 w-full lg:w-[40%]">
          <Thumbnail imgClassNames="h-36 lg:h-40 w-full lg:w-72" propertyImageUrl={imageUrl}>
            <>
              {showListingViewCount() && (
                <span
                  className={cn([
                    'absolute inline-flex sm:z-[1000] md:z-[70] lg:z-[1000] items-center p-3 lg:p-0 top-20 right-0 sm:top-20 sm:right-0 lg:top-1 lg:right-3',
                  ])}>
                  <Icon classNames="w-4 h-4 pr-[0.35rem]" name={IconName.EYE} />
                  <span className="text-xs text-cement-100"> {listingViewCount} Views </span>
                </span>
              )}

              {BuildingStatusText() && (
                <div className="building-status md:!z-[70] lg:z-[1000]">
                  <Typography variant="label-2">{BuildingStatusText()}</Typography>
                </div>
              )}
            </>
          </Thumbnail>

          {FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT && (
            <>
              <div className="absolute top-0 left-0 w-full h-1/3 bg-gradient-to-b from-slate-200 to-transparent"></div>
              <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full"></div>
            </>
          )}
        </div>

        <div className="result-card-info-container">
          <div className="flex justify-between">
            {availableTime !== '' && (
              <Typography className="available-time" variant="subtitle-3">
                {availableTime}
              </Typography>
            )}
          </div>
          <div className="result-name-address-container lg:py-[0.4rem] pb-[1rem]">
            <ConstrainedTypography
              className="text-black lg:text-cement-500 lg:pb-1"
              variant="subtitle-2"
              width="max-w-[95%]">
              {unitName}
            </ConstrainedTypography>

            <ConstrainedTypography className="text-cement-500" variant="body-4" width="max-w-[95%]">
              {address}
            </ConstrainedTypography>
          </div>
          <div className="attributes-container">
            {propertyType === PropertyType.PRE_DEVELOPMENT ? (
              <div className="md:mb-3 lg:mb-0">
                <Typography variant="body-5" className="property-item">
                  {acreage
                    ? formatNumberWithCommas(acreage, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : '- '}
                  <span className="attribute-title ml-[0.15rem]">Acres</span>
                </Typography>
              </div>
            ) : (
              <>
                <div>
                  <Typography variant="body-5" className="property-item">
                    {propertySize?.value ? formatNumberWithCommas(propertySize.value) : '-'}
                    <span className="attribute-title ml-[0.15rem]">
                      {getSizeUnit(propertyType!, propertySize?.unitOfMeasure!)}
                    </span>
                  </Typography>
                  <Typography variant="body-5" className="property-item">
                    {clearHeight ? `${clearHeight}'` : '-'}
                    <span className="attribute-title ml-[0.15rem]">Clear Height</span>
                  </Typography>
                </div>
                <div className="attributes-divider" />
                <div>
                  <Typography variant="body-5" className="property-item">
                    {dockDoors ? formatNumberWithCommas(dockDoors) : '-'}
                    <span className="attribute-title ml-[0.15rem]">Dock Doors</span>
                  </Typography>
                  <Typography variant="body-5" className="property-item">
                    {driveInDoors ? formatNumberWithCommas(driveInDoors) : '-'}
                    <span className="attribute-title ml-[0.15rem]">Drive In Doors</span>
                  </Typography>
                </div>
              </>
            )}
          </div>
        </div>

        {isBigScreen && (
          <div className="fav absolute right-[0.5rem] top-[0.5rem]">
            <span
              data-tooltip-id={`tooltip-${id}`}
              data-tooltip-place="left"
              className="sm:z-[100] md:z-[9] lg:z-[100] lg:mt-3 lg:mr-3 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleClickOnStar();
              }}>
              {isSaved || pendingFavorites.includes(id) ? (
                <Icon name={IconName.STAR_FILLED} />
              ) : (
                <Icon classNames={cn(['fav-icon'])} name={IconName.STAR} />
              )}
            </span>
            <Tooltip id={`tooltip-${id}`} place="left">
              <Typography variant="body-5" className="max-w-[12.5rem]">
                {isSaved ? 'Remove from Favorites' : 'Add to Favorites'}
              </Typography>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultCard;
