import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';

export function Authentication() {
  const { error } = useMsalAuthentication(InteractionType.Redirect);
  const { inProgress } = useMsal();

  if (error) {
    return <>{error.message}</>;
  }

  let loadingMessage = 'Checking authentication...';

  if (inProgress === InteractionStatus.Login) {
    loadingMessage = 'Logging you in to Property Search...';
  } else if (inProgress === InteractionStatus.Logout) {
    loadingMessage = 'Logging you out of Property Search...';
  } else if (inProgress === InteractionStatus.HandleRedirect) {
    loadingMessage = 'Redirecting you to Property Search...';
  }

  return <>{loadingMessage}</>;
}
